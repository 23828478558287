import React, { Component } from 'react'

export default class Card extends Component {
  render () {
    const { container } = styles
    const { children, style, shadow } = this.props
    const containerStyle = shadow ? container : ''
    return (
      <div style={{ ...containerStyle, borderRadius: 5, ...style }}>
        {children}
      </div>
    )
  }
}

const styles = {
  container: {
    WebkitBoxShadow: '10px 10px 57px -6px rgba(0,0,0,0.25)',
    MozBoxShadow: '10px 10px 57px -6px rgba(0,0,0,0.25)',
    boxShadow: '10px 10px 57px -6px rgba(0,0,0,0.25)'
  }
}
